import React, { useEffect } from "react";
import styled from "styled-components";

// Styles
import "../styles/plyr.css";

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;

  // max-width: calc((100vh + 28.12%) - 50px);
  // width: min(100%, calc((100vh + 28.12%) - 50px));
`;

export const HeroVideo = ({ url, id }) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      const player = new Plyr(`#${id}`, {
        autoplay: false,
        muted: false,
        loop: { active: true },
        controls: ["play", "fullscreen", "progress", "captions"],
        hideControls: false,
      });
    }
  }, []);

  return (
    <VideoWrapper>
      <div
        className="plyr__video-embed"
        id={id}
        dangerouslySetInnerHTML={{
          __html: url,
        }}
      />
    </VideoWrapper>
  );
};
