import React, { useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import { Col, Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import sal from "sal.js";
import "../components/styles/sal.css";
import { mergePrismicPreviewData } from "gatsby-source-prismic";

// Components
import { Video } from "../components/video/video";
import { HeroVideo } from "../components/video/hero-video";

const Page = styled.div`
  margin: 100px auto 0 auto;
  max-width: 1400px;
  position: relative;

  // max-width: calc((100vh + 28.12%) - 50px);
  // width: 100%;
  // width: min(100%, calc((100vh + 28.12%) - 50px));
`;

const Name = styled.div`
  text-align: center;

  & p {
    margin: 20px 0 0 0;
  }
`;

const InactiveName = styled(Name)`
  color: #c4c4c4;
`;

const InterviewContainer = styled.div`
  position: relative;

  margin: 0 0 80px 0;

  & .coming-soon-text {
    color: #c4c4c4;

    font-size: 14px;
    line-height: 18px;
    text-align: center;

    & p {
      margin: 0 auto;
    }
  }

  @media (max-width: 767px) {
    margin: 0 0 60px 0;
  }
`;

const SixteenNine = styled.div`
  position: relative;
  overflow: hidden;

  opacity: 0.5;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
  }
  & > .content {
    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  & img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
`;

const Section = styled.section`
  // min-height: calc(100vh - 50px - 50px);
  margin: 0 0 100px 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    margin: 0 0 60px 0;
  }
`;

const HeroVideoCaption = styled.div`
  margin: 0 0 100px 0;

  font-size: 14px;
  line-height: 18px;
  text-align: center;

  @media (max-width: 767px) {
    margin: 0 0 60px 0;
  }
`;

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== "undefined";

const Film = ({ data: staticData }) => {
  const data = useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      sal({
        threshold: 0.35,
        once: true,
      });
    }
  }, []);

  const interviews = data.prismicInterviews.data.interviews.map(
    (content, index) => (
      <Col
        col={12}
        md={6}
        key={`interview_video_${index + 1}`}
        data-sal="fade"
        data-sal-duration="450"
        data-sal-easing="ease-out-quad"
      >
        <InterviewContainer>
          {content.video.html !== null && (
            <>
              <Video
                url={content.video.html}
                id={`interview_video_${index + 1}`}
              />
              <Name
                dangerouslySetInnerHTML={{
                  __html: content.name.html,
                }}
              />
            </>
          )}

          {content.thumbnail.url !== null && (
            <>
              <SixteenNine>
                <div className="content">
                  <img src={content.thumbnail.url} alt={content.name.text} />
                </div>
              </SixteenNine>

              <InactiveName
                dangerouslySetInnerHTML={{
                  __html: content.name.html,
                }}
              />
              <div
                className="coming-soon-text"
                dangerouslySetInnerHTML={{
                  __html: content.coming_soon_text.html,
                }}
              />
            </>
          )}
        </InterviewContainer>
      </Col>
    )
  );

  return (
    <Page>
      <Helmet title="Film – A Delicate Sight" />

      <Row>
        <Col
          col={12}
          data-sal="fade"
          data-sal-duration="450"
          data-sal-easing="ease-out-quad"
        >
          {/* <Section> */}
          <HeroVideo
            url={data.prismicInterviews.data.video.html}
            id={`full_interview_video`}
            data-sal="fade"
          />
          <HeroVideoCaption
            data-sal="fade"
            dangerouslySetInnerHTML={{
              __html: data.prismicInterviews.data.full_video_text.html,
            }}
          />

          {/* </Section> */}
        </Col>
      </Row>

      {/* <Wrapper> */}
      <Row>{interviews}</Row>
      {/* </Wrapper> */}
    </Page>
  );
};

export const query = graphql`
  {
    prismicInterviews {
      data {
        video {
          html
        }
        interviews {
          thumbnail {
            url
          }
          video {
            html
          }
          name {
            html
          }
          coming_soon_text {
            html
          }
        }
        full_video_text {
          html
        }
      }
    }
  }
`;

export default Film;
